// Sidebar.jsx
//import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  Typography,
  IconButton, // Import IconButton
} from '@mui/material';
import {
  Settings as SettingsIcon,
  Chat as ChatIcon,
  Explore as ExploreIcon,
  Api as ApiIcon,
  Description as DescriptionIcon,
  Close as CloseIcon, // Import CloseIcon
} from '@mui/icons-material';
import Subscription from '@mui/icons-material/AddCard';
import { Link } from 'react-router-dom';
import { useAppContext } from '../AppProvider';
import ConversationItem from './conversationComponent';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import React, { useState } from 'react';
import { Modal,Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';



const SparklyBadge = () => (
  <Box
    sx={{
      position: 'relative',
      display: 'inline-block',
      marginLeft: '8px',
    }}
  >
    {/* Badge Background */}
    <Box
      sx={{
        backgroundColor: 'red',
        color: 'white',
        fontSize: '10px',
        fontWeight: 'bold',
        borderRadius: '8px',
        padding: '2px 6px',
        position: 'relative',
        zIndex: 2,
      }}
    >
      New
    </Box>

    {/* Sparkle Effect */}
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: '8px',
        background: 'radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 70%)',
        animation: 'sparkle 1.5s infinite',
        zIndex: 1,
        pointerEvents: 'none', // Ensures it doesn’t interfere with interactions
      }}
    />
    <style>
      {`
        @keyframes sparkle {
          0%, 100% {
            transform: scale(1);
            opacity: 1;
          }
          50% {
            transform: scale(1.5);
            opacity: 0;
          }
        }
      `}
    </style>
  </Box>
);


const Sidebar = ({
  isOpen,
  handleCloseSidebar,
  menuFontSize = '16px',
  conversationFontSize = '10px',
  createConversation,
  isMdUp
}) => {
  const sidebarWidth = 250; // You can adjust this value as needed

  const {
    version,
    setVersion,
    currentConversation,
    conversations,
    setConversations,
    setCurrentConversation,
    queries,
    setQueries,
    ids,
    setIds,
    idHistory,
    setIdHistory,
    setMode,
    setLink,
    errorDisplay,
    setErrorDisplay,
    setIsSearchMoved,
    setNewSearch,
  } = useAppContext();

  const handleConversationSelect = (conversation) => {
    setErrorDisplay("");
    setNewSearch(false);
    if (currentConversation !== conversation.id) {
      setQueries([]); // Reset queries or any other state related to conversations if needed
      setIds([]);
      setIdHistory({});
      setCurrentConversation(conversation.id);
    }
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={handleCloseSidebar}
      variant="persistent" // Changed to 'persistent' to keep background interactive
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
        // Optional: Customize backdrop if needed
        // BackdropProps: {
        //   style: { backgroundColor: 'transparent', pointerEvents: 'none' },
        // },
      }}
      sx={{
        '& .MuiDrawer-paper': {
          width: sidebarWidth,
          boxSizing: 'border-box',
          p: 2,
        },
        // Optional: Ensure no backdrop is displayed
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent',
          pointerEvents: 'none',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
        role="presentation"
        // Removed onClick and onKeyDown to prevent unintended closing
      >
        {/* Logo or Branding with Hide Button */}
        <Box
          sx={{
            // mb: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left', // Center the content horizontally
            textAlign: 'center', // Ensure the text is centered
          }}
        >
          {/* <Typography variant="h5" color="#0066FF">
            Facticity.AI
          </Typography> */}
            <a href="https://facticity.ai" style={{ textDecoration: 'none' }}>
              <img
                  src="/facticityailogo-03.png"
                  alt="Facticity.AI"
                  style={{
                  paddingTop: '4px',
                  paddingLeft: isMdUp ? '13px' : '10px',
                  width: isMdUp ? '28px' : '28px', // Adjust the width as needed
                  height: 'auto', // Maintains aspect ratio
                  }}
              />
            </a>
          {/* Hide Button */}
          <IconButton
            onClick={handleCloseSidebar}
            aria-label="Close sidebar"
            size="large"
            sx={{
              position: 'absolute', // Position the button separately
              right: 0, // Align the button to the right
            }}
          >
            <SpaceDashboardOutlinedIcon />
          </IconButton>
        </Box>


        {/* <Divider /> */}

        {/* Menu Items */}
        <List>
          {/* Discover Button with Tooltip */}
          <Tooltip title="Discover coming soon!" placement="right" arrow>
            <ListItemButton
              sx={{
                fontSize: menuFontSize,
                margin: '0px 0',
                color: 'grey.500',
              }}
              disabled
            >
              <ListItemIcon>
                <ExploreIcon />
              </ListItemIcon>
              <ListItemText primary="Discover" />
            </ListItemButton>
          </Tooltip>

{/* Word Add-in Button with Tooltip */}
      <Tooltip title="Try it out now!" placement="right" arrow>
        <ListItemButton
          sx={{
            fontSize: '16px', // Adjust font size dynamically if needed
            margin: '8px 0',
            color: 'grey.700',
          }}
          component={Link}
          to="/writer"
          // onClick={handleOpen} // Opens the modal when clicked
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Writer" />
          {/* <SparklyBadge/> */}
        </ListItemButton>
      </Tooltip>
      {/* <Tooltip title="Try it out now!" placement="right" arrow>
        <ListItemButton
          sx={{
            fontSize: '16px',
            margin: '8px 0',
            color: 'grey.700',
            animation: `${pulseAnimation} 2s infinite`, // Add animation
            transition: 'all 0.3s ease-in-out', // Smooth transition for hover effects
            '&:hover': {
              color: 'orange',
              boxShadow: '0 0 15px rgba(255, 165, 0, 0.8)',
            },
          }}
          component={Link}
          to="/writer"
          // onClick={handleOpen}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Writer Add-in" />
        </ListItemButton>
      </Tooltip> */}
      {/* Modal for "Coming Soon" */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="coming-soon-title"
        aria-describedby="coming-soon-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxWidth: 400,
            textAlign: 'center',
          }}
        >
          <Typography id="coming-soon-title" variant="h6" component="h2">
            Coming Soon!
          </Typography>
          <Typography id="coming-soon-description" sx={{ mt: 2 }}>
            The <strong>'Facticity.AI MS Word Add-in' </strong> is going to be launched soon!. By signing-up to the wait list, you can enjoy <strong>one month free-trial</strong>. Sign up below!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            href="https://tally.so/r/mV1V0v" // sign-up link
            target="_blank"
          >
            Sign Up
          </Button>
          <Button
            variant="text"
            sx={{ mt: 1 }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Modal>

          {/* API Button */}
          <ListItemButton
            component={Link}
            to="/api"
            sx={{
              fontSize: menuFontSize,
              margin: '8px 0',
              color: 'grey.700',
            }}
          >
            <ListItemIcon>
              <ApiIcon />
            </ListItemIcon>
            <ListItemText primary="API" />
          </ListItemButton>

          {/* Payments Button */}
          <ListItemButton
            component={Link}
            to="/subscription"
            sx={{
              fontSize: menuFontSize,
              margin: '8px 0',
              color: 'grey.700',
            }}
          >
            <ListItemIcon>
              <Subscription />
            </ListItemIcon>
            <ListItemText primary="Subscription" />
          </ListItemButton>

          {/* New Chat Button */}
          <ListItemButton
            onClick={(e) => {
              e.stopPropagation(); // Prevent Drawer from closing
              createConversation();
            }}
            sx={{
              fontSize: menuFontSize,
              margin: '8px 0',
              color: 'grey.700',
            }}
          >
            <ListItemIcon>
              <EditNoteIcon />
            </ListItemIcon>
            <ListItemText primary="New Chat" />
          </ListItemButton>
        </List>

        <Divider />

        {/* Conversations List */}
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            mt: 2,
          }}
        >
          <List>
            {conversations
              .slice()
              .reverse()
              .map((conversation) => (
                <ConversationItem
                  key={conversation.id}
                  conversation={conversation}
                  currentConversation={currentConversation}
                  handleConversationSelect={handleConversationSelect}
                  conversationItemStyle={{
                    fontSize: conversationFontSize,
                    paddingLeft: '24px',
                    wordBreak: 'break-all',
                    overflowWrap: 'break-word',
                    margin: '4px 0',
                    padding: '6px 12px',
                    borderRadius: '8px',
                    '&.Mui-selected': {
                      backgroundColor: '#F1F3FE',
                      '&:hover': {
                        backgroundColor: '#F1F3FE',
                      },
                    },
                  }}
                />
              ))}
          </List>
        </Box>

        <Divider />

        {/* Settings Section */}
        <List>
          <ListItemButton
            component={Link}
            to="/settings"
            sx={{
              fontSize: menuFontSize,
              margin: '8px 0',
              color: 'grey.500',
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
          {/* Divider and Additional Links */}
     {/* Additional Section */}
     <Box sx={{ mt: 4, px: 2, textAlign: 'center' }}>
        {/* Company Name */}
        <Typography
          //variant="h6"
          sx={{
            color: 'grey.800',
            fontWeight: 'bold',
          }}
        >
         <a
  href="https://www.aiseer.co" // Replace with your website URL
  target="_blank"
  style={{ color: 'grey' }} // Applying gray color directly
  sx={{
    color: 'grey.600', // Material-UI's gray.600 color
    textDecoration: 'none', // Removing underline
  }}
>
  AI Seer
</a>

        </Typography>

        {/* About and Social Media Icons */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
            gap: 2, // Space between icons
          }}
        >
          <Link href="/about" color="inherit" underline="none">
            <InfoIcon sx={{ color: 'grey.600', fontSize: 24 }} />
          </Link>
          <a href="https://www.linkedin.com/company/aiseer" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} aria-label="LinkedIn">
  <LinkedInIcon sx={{ color: 'grey.600', fontSize: 24 }} />
</a>
<a href="https://x.com/facticityai" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} aria-label="Twitter">
  <TwitterIcon sx={{ color: 'grey.600', fontSize: 24 }} />
</a>
<a href="https://www.instagram.com/facticity.ai/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} aria-label="Instagram">
  <InstagramIcon sx={{ color: 'grey.600', fontSize: 24 }} />
</a>
<a href="https://www.youtube.com/@facticityai" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} aria-label="YouTube">
  <YouTubeIcon sx={{ color: 'grey.600', fontSize: 24 }} />
</a>
<a href="https://www.facebook.com/profile.php?id=61560519085589" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} aria-label="Facebook">
  <FacebookIcon sx={{ color: 'grey.600', fontSize: 24 }} />
</a>
        </Box>
      </Box>
        </List>
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseSidebar: PropTypes.func.isRequired, // Corrected prop name
  menuFontSize: PropTypes.string,
  conversationFontSize: PropTypes.string,
  createConversation: PropTypes.func.isRequired, // Added missing prop type
};

export default Sidebar;
