// import React, { useState, useEffect } from 'react';
// import { Container, Card, CardContent, Typography, List, Divider, ListItem, ListItemText } from '@mui/material';
// import { useAuth0 } from '@auth0/auth0-react';
// import AccountSection from './AccountSection';
// import AppSection from './AppSection';
// import AboutSection from './AboutSection';
// import Loader from './Loader';

// const Settings = () => {
//   const [openAccount, setOpenAccount] = useState(true);
//   const [openApp, setOpenApp] = useState(false);
//   const [openAbout, setOpenAbout] = useState(false);
//   const { user, isAuthenticated, logout } = useAuth0();
//   const [profile, setProfile] = useState({});
//   const [loading, setLoading] = useState(null);
//   const [idx, setIdx] = useState(0);

//   const handleToggle = (setter) => () => {
//     setter((prevOpen) => !prevOpen);
//   };

//   const fetchProfile = async () => {
//     if(user){
//       setLoading(true); // Start loading
//       const url = `https://facticity-backend-a4xncz5jja-as.a.run.app/get-user/${user.email}`;
//       const response = await fetch(url);
//       const data = await response.json();
//       let temp_profile;
//       if (data[0]?.user_data[0]?.user_metadata) {
//         if (data[0]?.user_data[0]) {
//           temp_profile = data[0];
//           setIdx(0);
//         } else if (data[1]?.user_data[1]?.user_metadata) {
//           temp_profile = data[1];
//           setIdx(1);
//         } else {
//           temp_profile = null;
//         }
      
//         if (temp_profile) {
//           setProfile(temp_profile);
//         } else {
//           console.error('No user metadata found');
//         }
//       } 
//       setProfile(data[0]);
//       setLoading(false); // Stop loading after data is fetched  
//     }
//   };

//   useEffect(() => {
//     fetchProfile();
//   }, [user]);

//   if (loading) {
//     return <Loader />;
//   }

//   return (
//     <Container maxWidth="md" sx={{ mt: 5 }}>
//       <Card variant="outlined">
//         <CardContent>
//           <Typography variant="h5" align="center" gutterBottom>
//             Settings
//           </Typography>
//           <List component="nav">
//             {profile?.user_data?.[idx] && (
//               <AccountSection 
//                 open={openAccount} 
//                 onToggle={handleToggle(setOpenAccount)} 
//                 profile={profile} 
//                 idx={idx} 
//               />
//             )}
//             <AppSection 
//               open={openApp} 
//               onToggle={handleToggle(setOpenApp)} 
//             />
//             <AboutSection 
//               open={openAbout} 
//               onToggle={handleToggle(setOpenAbout)} 
//             />
//             <Divider />
//             <ListItem button onClick={() => logout({ returnTo: window.location.origin })}>
//               <ListItemText primary="Log Out" />
//             </ListItem>
//           </List>
//         </CardContent>
//       </Card>
//     </Container>
//   );
// };

// export default Settings;


import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  CardContent,
  Typography,
  List,
  Divider,
  ListItem,
  ListItemText,
  Button,
  Box
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import AccountSection from './AccountSection';
import AppSection from './AppSection';
import AboutSection from './AboutSection';
import Loader from './Loader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Optional: For back icon

const Settings = () => {
  const [openAccount, setOpenAccount] = useState(true);
  const [openApp, setOpenApp] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);
  const { user, isAuthenticated, logout } = useAuth0();
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(null);
  const [idx, setIdx] = useState(0);

  const navigate = useNavigate(); // Initialize useNavigate

  const handleToggle = (setter) => () => {
    setter((prevOpen) => !prevOpen);
  };

  const fetchProfile = async () => {
    if (user) {
      setLoading(true); // Start loading
      const url = `https://backend-word-testing-934923488639.us-central1.run.app/get-user/${user.email}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        let temp_profile;
        if (data[0]?.user_data[0]?.user_metadata) {
          if (data[0]?.user_data[0]) {
            temp_profile = data[0];
            setIdx(0);
          } else if (data[1]?.user_data[1]?.user_metadata) {
            temp_profile = data[1];
            setIdx(1);
          } else {
            temp_profile = null;
          }

          if (temp_profile) {
            setProfile(temp_profile);
          } else {
            console.error('No user metadata found');
          }
        }
        setProfile(data[0]);
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false); // Stop loading after data is fetched
      }
    }
  };

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      {/* Back Button */}
      <Box mb={2}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/')}
        >
          Back
        </Button>
      </Box>

      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5" align="center" gutterBottom>
            Settings
          </Typography>
          <List component="nav">
            {profile?.user_data?.[idx] && (
              <AccountSection
                open={openAccount}
                onToggle={handleToggle(setOpenAccount)}
                profile={profile}
                idx={idx}
              />
            )}
            <AppSection
              open={openApp}
              onToggle={handleToggle(setOpenApp)}
            />
            {/* <AboutSection
              open={openAbout}
              onToggle={handleToggle(setOpenAbout)}
            /> */}
            <Divider />
            <ListItem button onClick={() => logout({ returnTo: window.location.origin })}>
              <ListItemText primary="Log Out" />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Settings;
