// // SearchComponent.jsx
// import React, { useState, useEffect, useRef } from 'react';
// import {
//   Typography,
//   Box,
//   Paper,
//   Skeleton,
//   useMediaQuery,
//   useTheme,
//   Container,
// } from "@mui/material";
// import FactCheckDisplay from '../FactCheckDisplay';
// import './animation.css'; // Ensure .search-container styles are removed/commented out
// import './scrollbar.css';
// import { useAppContext } from '../AppProvider';
// import { v4 as uuidv4 } from 'uuid';
// import SearchBar from './searchBar';
// import ErrorComponent from './ErrorComponent';
// import VideoParagraphComponent from '../videoExpand';
// import { useAuth0 } from '@auth0/auth0-react';

// const SearchComponent = ({ isSearchMoved, setIsSearchMoved }) => {
//   const [searchQuery, setSearchQuery] = useState("");
//   const [isProMode, setIsProMode] = useState(false);
//   const { isAuthenticated } = useAuth0();

//   const [searchCount, setSearchCount] = useState(0);
//   const {
//     version,
//     setVersion,
//     currentConversation,
//     setConversations,
//     setCurrentConversation,
//     queries,
//     setQueries,
//     email,
//     ids,
//     workspaceLoading,
//     mode,
//     link,
//     setLink,
//     setIdHistory,
//     setMode,
//     errorDisplay,
//     setErrorDisplay,
//     setNewSearch,
//     setOverlayLogin,
//     setHideSearchBar,
//     skipDisambiguation
//   } = useAppContext();
  
//   const theme = useTheme();
//   const isSmUp = useMediaQuery(theme.breakpoints.up('sm')); // >=600px
//   const isMdUp = useMediaQuery(theme.breakpoints.up('md')); // >=900px
//   const scrollableDivRef = useRef(null);
//   const bottomRef = useRef(null); // **New Ref for Sentinel Element**

//   const [text, setText] = useState("");
//   const fullText = "Let's get fact-checking!"; // The full text to type out
//   const typingSpeed = 50; // Typing speed in milliseconds
//   const indexRef = useRef(0); // To keep track of the current index
//   const timeoutRef = useRef(null); // To store the timeout ID

//   useEffect(() => {
//     const typeWriter = () => {
//       // Ensure that fullText is defined and index is within bounds
//       if (indexRef.current < fullText.length) {
//         const currentChar = fullText[indexRef.current];
        
//         // Prevent appending undefined or null characters
//         if (currentChar !== undefined && currentChar !== null) {
//           setText((prev) => prev + currentChar);
//           indexRef.current += 1;
//           timeoutRef.current = setTimeout(typeWriter, typingSpeed);
//         } else {
//           // If currentChar is undefined or null, stop the typing
//           clearTimeout(timeoutRef.current);
//         }
//       }
//     };

//     typeWriter();

//     // Cleanup function to clear the timeout when the component unmounts
//     return () => {
//       if (timeoutRef.current) {
//         clearTimeout(timeoutRef.current);
//       }
//       setText(''); // Optional: Reset text when unmounting
//       indexRef.current = 0; // Reset index
//     };
//   }, [fullText, typingSpeed]);


//   const handleToggle = (event) => {
//     setIsProMode(event.target.checked);
//     setVersion(event.target.checked ? "pro" : "basic");
//   };

//   useEffect(() => {
//     setIsProMode(version === "pro");
//   }, [version]);

//   const sendCurrentConversation = async (email, currentConversation, query) => {
//     try {
//       await fetch('https://backend-word-testing-934923488639.us-central1.run.app/update_ids', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//           email: email, // user email
//           id: {
//             id: currentConversation, // current conversation ID
//             query: query // conversation query
//           }
//         })
//       });
//     } catch (error) {
//       console.error("Error sending current conversation:", error);
//     }
//   };

//   const isUrl = (string) => {
//     try {
//       new URL(string);
//       return true;
//     } catch (_) {
//       return false;
//     }
//   };

//   const handleSearchClick = async () => {
    
//     setErrorDisplay("");
//     // Clear the search input and move the search UI if needed
//     setSearchQuery("");
//     setIsSearchMoved(true);
//     const trimmedQuery = searchQuery.trim();

//     if (!trimmedQuery) return;

//     // console.log("searchCount is: ", searchCount)
//     // console.log("isURL: ", isUrl(trimmedQuery))
//     if(!isAuthenticated && searchCount >=1 || !isAuthenticated && isUrl(trimmedQuery) || !isAuthenticated && trimmedQuery.length>250){
//       // alert('Please login to continue searching');
//       setOverlayLogin(true)
//       return;
//     }

//     let newMode = mode;
//     let newQueries = [...queries];
//     // console.log({ newQueries });
//     let newLink = null;

//     let newSearchState = false;
//     // Determine if a new conversation should be started
//     const shouldStartNewConversation =
//       queries.length === 0 || isUrl(trimmedQuery) || mode === 'extractFacts';

//     if (isUrl(trimmedQuery)) {
//       newLink = trimmedQuery;
//       newQueries = [trimmedQuery];
//       newMode = 'extractFacts';
//       setNewSearch(true);
//     } else if (trimmedQuery.length > 250){
//       newLink = trimmedQuery;
//       newQueries = [trimmedQuery];
//       newMode = 'extractFacts';
//       setNewSearch(true);
//     } else if (mode === 'extractFacts') {
//       newMode = 'verify';
//       newQueries = [trimmedQuery];
//       setNewSearch(true);
//     } else {
//       newMode = 'verify';
//       newQueries.push(trimmedQuery);
//     }

//     // Update states based on the above decisions
//     if (newLink) setLink(newLink);
//     setMode(newMode);
//     setQueries(newQueries);
//     // console.log({ trimmedQuery });

//     if (shouldStartNewConversation) {
//       setIdHistory({});
//       let newConversationId = ""
//       if (isAuthenticated){
//         newConversationId = uuidv4();
//       }else{
//         newConversationId = "";

//       }
//       setCurrentConversation(newConversationId);

//       setConversations((prevIds) => [
//         ...prevIds,
//         { query: trimmedQuery, id: newConversationId },
//       ]);

//       // Send conversation data and update URL without page navigation
//       await sendCurrentConversation(email, newConversationId, trimmedQuery);
//       window.history.replaceState(null, "", `/c/${newConversationId}`);
//     }
//     if(!isAuthenticated){
//       setSearchCount(searchCount + 1)
//     }
//   };


//   // useEffect(() => {
//   //   console.log({ queries });
//   // }, [queries]);

//   // **Remove the previous useEffect for scrolling based on queries**

//   // **Add a new useEffect to scroll to the sentinel element after each render**
//   useEffect(() => {
//     if (bottomRef.current) {
//       bottomRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [queries, workspaceLoading, mode, link]); // Add dependencies as needed

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%', // Changed from '100vh' to '100%' to prevent overflow
//         width: '100%',
//         position: 'relative',
//         backgroundColor: 'transparent',
//         overflow: 'hidden', // Prevent overflow
//         // border: '2px solid red', // **Border Added for Debugging**
//         boxSizing: 'border-box', // Ensures padding and border are included in the element's total width and height
//       }}
//     >
//       {/* Parent Container for Search Bar */}
//       {/* <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: isSearchMoved ? 'flex-end' : 'center',
//           position: 'absolute',
//           top: isSearchMoved ? 'auto' : '50%',
//           bottom: isSearchMoved ? '35px' : 'auto',
//           left: '50%',
//           transform: isSearchMoved
//             ? 'translateX(-50%)'
//             : 'translate(-50%, -50%)',
//           transition: 'all 0.3s ease',
//           width: {
//             xs: '95%', // Mobile
//             sm: '70%', // Small screens
//             md: '50%', // Medium and up
//           },
//           maxWidth: '900px',
//           zIndex: 1000, // Higher z-index to sit on top
//           pointerEvents: 'none', // Allow clicks to pass through non-interactive areas
//           flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
//           // border: '2px solid orange', // **Border Added for Debugging**
//           boxSizing: 'border-box',
//         }}
//       >
//         <Box
//           sx={{
//             pointerEvents: 'auto', // Enable interactions within the SearchBar
//             width: '100%',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             // border: '2px dashed blue', // **Border Added for Debugging**
//             boxSizing: 'border-box',
//           }}
//         >
//           <SearchBar
//             searchQuery={searchQuery}
//             setSearchQuery={setSearchQuery}
//             handleSearchClick={handleSearchClick}
//             isProMode={isProMode}
//             handleToggle={handleToggle}
//             isSearchMoved={isSearchMoved}
//             sx={{
//               padding: { xs: '8px 12px', sm: '12px 16px' }, // Add padding for mobile
//               boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', // Subtle shadow for visibility
//               // border: '2px solid purple', // **Border Added for Debugging**
//               boxSizing: 'border-box',
//             }}
//           />
//         </Box>
//       </Box> */}
//     <Box
//       sx={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: isSearchMoved ? 'flex-end' : 'center',
//         position: 'fixed', // Changed from 'absolute' to 'fixed'
//         top: isSearchMoved ? 'auto' : '50%',
//         bottom: isSearchMoved ? '35px' : 'auto',
//         left: '50%',
//         transform: isSearchMoved
//           ? 'translateX(-50%)'
//           : 'translate(-50%, -50%)',
//         transition: 'all 0.3s ease',
//         width: {
//           xs: '95%', // Mobile
//           sm: '70%', // Small screens
//           md: '50%', // Medium and up
//         },
//         maxWidth: '900px',
//         zIndex: 1000, // Higher z-index to sit on top
//         pointerEvents: 'none', // Allow clicks to pass through non-interactive areas
//         flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
//         // border: '2px solid orange', // **Border Added for Debugging**
//         boxSizing: 'border-box',
//       }}
//     >
//       <Box
//         sx={{
//           pointerEvents: 'auto', // Enable interactions within the SearchBar
//           width: '100%',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           // border: '2px dashed blue', // **Border Added for Debugging**
//           boxSizing: 'border-box',
//         }}
//       >
//         <SearchBar
//           searchQuery={searchQuery}
//           setSearchQuery={setSearchQuery}
//           handleSearchClick={handleSearchClick}
//           isProMode={isProMode}
//           handleToggle={handleToggle}
//           isSearchMoved={isSearchMoved}
//           sx={{
//             padding: { xs: '8px 12px', sm: '12px 16px' }, // Add padding for mobile
//             boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', // Subtle shadow for visibility
//             // border: '2px solid purple', // **Border Added for Debugging**
//             boxSizing: 'border-box',
//             width: '100%', // Ensure the SearchBar takes full width of its container
//             maxWidth: '800px', // Optional: limit the max width of the SearchBar
//           }}
//         />
//       </Box>
//     </Box>

//       {/* Content Area */}
//       <Container
//         maxWidth="xl"
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           flexGrow: 1, // Allow the Container to grow and fill available space
//           height: isSearchMoved ? 'calc(100% - 80px)' : '100%', // Conditional height
//           transition: 'all 0.3s ease',
//           position: 'relative',
//           zIndex: 0, // Base layer
//           mt: isSearchMoved ? 0 : 0, // Adjust top margin when search is moved
//           overflow: 'hidden', // Prevent overflow
//           // border: '2px solid blue', // **Border Added for Debugging**
//           boxSizing: 'border-box',
//         }}
//       >
//         {/* Loading State */}
//         {workspaceLoading ? (
//           <Box sx={{ width: '100%', mt: 4, boxSizing: 'border-box' }}> {/* **Border Added for Debugging** */}
//             <Skeleton
//               variant="text"
//               sx={{ width: '100%', maxWidth: 800, mx: 'auto' }}
//               height={60}
//             />
//             <Skeleton
//               variant="rectangular"
//               sx={{ width: '100%', maxWidth: 800, mx: 'auto' }}
//               height={400}
//             />
//             <Skeleton
//               variant="text"
//               sx={{ width: '100%', maxWidth: 800, mx: 'auto' }}
//               height={40}
//             />
//           </Box>
//         ) : (
//           <Box
//             sx={{
//               display: 'flex',
//               flexDirection: 'column',
//               flexGrow: 1,
//               alignItems: 'center',
//               justifyContent: queries.length ? 'flex-start' : 'center',
//               overflow: 'hidden', // Prevent overflow
//               // border: '2px solid brown', // **Border Added for Debugging**
//               boxSizing: 'border-box',
//             }}
//           >
//             {/* Initial State */}
//             {!queries.length && (
//               <Box
//                 sx={{
//                   textAlign: 'center',
//                   mb: 4,
//                   // border: '2px solid teal', // **Border Added for Debugging**
//                   boxSizing: 'border-box',
//                 }}
//               >
//                 {errorDisplay ? (
//                   <ErrorComponent errorDisplay={errorDisplay} />
//                 ) : (
//                   <Box
//                   sx={{
//                     transform: 'translateY(-160px)',
//                     // border: '2px dashed navy', // **Border Added for Debugging**
//                     boxSizing: 'border-box',
//                     display: 'flex',
//                     alignItems: 'center',
//                     flexWrap: 'nowrap', // Prevent wrapping
//                     padding: '16px', // Optional: Add some padding
//                     // Optional: Handle overflow if necessary
//                     // overflow: 'hidden',
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: 'flex',
//                       alignItems: 'center',
//                       // border: '2px solid lime', // **Border Added for Debugging**
//                       boxSizing: 'border-box',
//                       flexShrink: 0, // Prevent the image box from shrinking
//                       marginRight: '16px', // Space between image and text
//                     }}
//                   >

//                   </Box>
//                     <Typography
//                       variant="h4"
//                       color="#0066FF"
//                       sx={{
//                         boxSizing: 'border-box',
//                         flexGrow: 1, // Allow text to take up remaining space
//                         textAlign: 'left', // Align text to the left
//                       }}
//                     >
//                       {text}
//                     </Typography>
//                 </Box>
 
//                 )}
//               </Box>
//             )}

//             {/* Results Area */}
//             {queries.length > 0 && (
//               <Box
//                 ref={scrollableDivRef}
//                 className="scrollable-container"
//                 sx={{
//                   width: '100%',
//                   flexGrow: 1,
//                   overflowY: 'auto', // Enable vertical scrolling
//                   scrollBehavior: 'smooth',
//                   // Removed mt: 2 and pb: 4 to reduce extra space
//                   maxHeight: '80vh', // Set your desired max height
//                   // border: '2px solid gray', // **Border Added for Debugging**
//                   boxSizing: 'border-box',
//                 }}
//               >
//                 <Paper
//                   elevation={0}
//                   sx={{
//                     width: '100%',
//                     maxWidth: mode === 'verify' ? '1100px' : '2200px',
//                     backgroundColor: 'transparent',
//                     // border: '2px solid purple', // **Border Added for Debugging**
//                     boxShadow: 'none',
//                     borderRadius: '10px',
//                     margin: 'auto',
//                     // padding: { xs: 1, sm: 2 },
//                     boxSizing: 'border-box',
//                   }}
//                 >
//                   {mode === 'verify' ? (
//                     <>
//                     {queries.map((query, index) => (
//                       <FactCheckDisplay
//                         key={index}
//                         query={query}
//                         id={ids[index] || null}
//                         setDone={() => {}}
//                         process
//                         maxWidth="100%"
//                         skipDisambiguation={skipDisambiguation}
//                         sx={{
//                           // border: "2px solid pink", // **Border Added for Debugging** (if FactCheckDisplay accepts sx prop)
//                           boxSizing: "border-box",
//                         }}
//                       />
//                     ))}
//                     {/* Add line breaks here */}
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     </>
//                   ) : (
//                     <VideoParagraphComponent
//                       key={0}
//                       id={0}
//                       claim={link}
//                       email={email}
//                       readyin={false}
//                       sx={{
//                         // border: '2px solid cyan', // **Border Added for Debugging** (if VideoParagraphComponent accepts sx prop)
//                         boxSizing: 'border-box',
//                       }}
//                     />
//                   )}
//                   {/* **Sentinel Element for Scrolling** */}
//                   <div ref={bottomRef} 
//                   style={{ height: 0, 
//                     // border: '2px dotted black' 
//                     }} /> {/* **Border Added for Debugging** */}
//                 </Paper>
//               </Box>
//             )}
//           </Box>
//         )}
//       </Container>
//     </Box>
//   );
// };

// export default SearchComponent;


// SearchComponent.jsx
// import React, { useState, useEffect, useRef } from 'react';
// import {
//   Typography,
//   Box,
//   Paper,
//   Skeleton,
//   useMediaQuery,
//   useTheme,
//   Container,
// } from "@mui/material";
// import FactCheckDisplay from '../FactCheckDisplay';
// import './animation.css'; // Ensure .search-container styles are removed/commented out
// import './scrollbar.css';
// import { useAppContext } from '../AppProvider';
// import { v4 as uuidv4 } from 'uuid';
// import SearchBar from './searchBar';
// import ErrorComponent from './ErrorComponent';
// import VideoParagraphComponent from '../videoExpand';
// import { useAuth0 } from '@auth0/auth0-react';

// const SearchComponent = ({ isSearchMoved, setIsSearchMoved }) => {
//   const [searchQuery, setSearchQuery] = useState("");
//   const [isProMode, setIsProMode] = useState(false);
//   const { isAuthenticated } = useAuth0();

//   const [searchCount, setSearchCount] = useState(0);
//   const {
//     version,
//     setVersion,
//     currentConversation,
//     setConversations,
//     setCurrentConversation,
//     queries,
//     setQueries,
//     email,
//     ids,
//     workspaceLoading,
//     mode,
//     link,
//     setLink,
//     setIdHistory,
//     setMode,
//     errorDisplay,
//     setErrorDisplay,
//     setNewSearch,
//     setOverlayLogin,
//     setHideSearchBar,
//     skipDisambiguation
//   } = useAppContext();
  
//   const theme = useTheme();
//   const isSmUp = useMediaQuery(theme.breakpoints.up('sm')); // >=600px
//   const isMdUp = useMediaQuery(theme.breakpoints.up('md')); // >=900px
//   const scrollableDivRef = useRef(null);
//   const bottomRef = useRef(null); // **New Ref for Sentinel Element**

//   const [text, setText] = useState("");
//   const fullText = "Let's get fact-checking!"; // The full text to type out
//   const typingSpeed = 50; // Typing speed in milliseconds
//   const indexRef = useRef(0); // To keep track of the current index
//   const timeoutRef = useRef(null); // To store the timeout ID

//   useEffect(() => {
//     const typeWriter = () => {
//       // Ensure that fullText is defined and index is within bounds
//       if (indexRef.current < fullText.length) {
//         const currentChar = fullText[indexRef.current];
        
//         // Prevent appending undefined or null characters
//         if (currentChar !== undefined && currentChar !== null) {
//           setText((prev) => prev + currentChar);
//           indexRef.current += 1;
//           timeoutRef.current = setTimeout(typeWriter, typingSpeed);
//         } else {
//           // If currentChar is undefined or null, stop the typing
//           clearTimeout(timeoutRef.current);
//         }
//       }
//     };

//     typeWriter();

//     // Cleanup function to clear the timeout when the component unmounts
//     return () => {
//       if (timeoutRef.current) {
//         clearTimeout(timeoutRef.current);
//       }
//       setText(''); // Optional: Reset text when unmounting
//       indexRef.current = 0; // Reset index
//     };
//   }, [fullText, typingSpeed]);


//   const handleToggle = (event) => {
//     setIsProMode(event.target.checked);
//     setVersion(event.target.checked ? "pro" : "basic");
//   };

//   useEffect(() => {
//     setIsProMode(version === "pro");
//   }, [version]);

//   const sendCurrentConversation = async (email, currentConversation, query) => {
//     try {
//       await fetch('https://backend-word-testing-934923488639.us-central1.run.app/update_ids', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//           email: email, // user email
//           id: {
//             id: currentConversation, // current conversation ID
//             query: query // conversation query
//           }
//         })
//       });
//     } catch (error) {
//       console.error("Error sending current conversation:", error);
//     }
//   };

//   const isUrl = (string) => {
//     try {
//       new URL(string);
//       return true;
//     } catch (_) {
//       return false;
//     }
//   };

//   const handleSearchClick = async () => {
    
//     setErrorDisplay("");
//     // Clear the search input and move the search UI if needed
//     setSearchQuery("");
//     setIsSearchMoved(true);
//     const trimmedQuery = searchQuery.trim();

//     if (!trimmedQuery) return;

//     // console.log("searchCount is: ", searchCount)
//     // console.log("isURL: ", isUrl(trimmedQuery))
//     if(!isAuthenticated && searchCount >=1 || !isAuthenticated && isUrl(trimmedQuery) || !isAuthenticated && trimmedQuery.length>250){
//       // alert('Please login to continue searching');
//       setOverlayLogin(true)
//       return;
//     }

//     let newMode = mode;
//     let newQueries = [...queries];
//     // console.log({ newQueries });
//     let newLink = null;

//     let newSearchState = false;
//     // Determine if a new conversation should be started
//     const shouldStartNewConversation =
//       queries.length === 0 || isUrl(trimmedQuery) || mode === 'extractFacts';

//     if (isUrl(trimmedQuery)) {
//       newLink = trimmedQuery;
//       newQueries = [trimmedQuery];
//       newMode = 'extractFacts';
//       setNewSearch(true);
//     } else if (trimmedQuery.length > 250){
//       newLink = trimmedQuery;
//       newQueries = [trimmedQuery];
//       newMode = 'extractFacts';
//       setNewSearch(true);
//     } else if (mode === 'extractFacts') {
//       newMode = 'verify';
//       newQueries = [trimmedQuery];
//       setNewSearch(true);
//     } else {
//       newMode = 'verify';
//       newQueries.push(trimmedQuery);
//     }

//     // Update states based on the above decisions
//     if (newLink) setLink(newLink);
//     setMode(newMode);
//     setQueries(newQueries);
//     // console.log({ trimmedQuery });

//     if (shouldStartNewConversation) {
//       setIdHistory({});
//       let newConversationId = ""
//       if (isAuthenticated){
//         newConversationId = uuidv4();
//       }else{
//         newConversationId = "";

//       }
//       setCurrentConversation(newConversationId);

//       setConversations((prevIds) => [
//         ...prevIds,
//         { query: trimmedQuery, id: newConversationId },
//       ]);

//       // Send conversation data and update URL without page navigation
//       await sendCurrentConversation(email, newConversationId, trimmedQuery);
//       window.history.replaceState(null, "", `/c/${newConversationId}`);
//     }
//     if(!isAuthenticated){
//       setSearchCount(searchCount + 1)
//     }
//   };


//   // useEffect(() => {
//   //   console.log({ queries });
//   // }, [queries]);

//   // **Remove the previous useEffect for scrolling based on queries**

//   // **Add a new useEffect to scroll to the sentinel element after each render**
//   useEffect(() => {
//     if (bottomRef.current) {
//       bottomRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [queries, workspaceLoading, mode, link]); // Add dependencies as needed

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%', // Changed from '100vh' to '100%' to prevent overflow
//         width: '100%',
//         position: 'relative',
//         backgroundColor: 'transparent',
//         overflow: 'hidden', // Prevent overflow
//         // border: '2px solid red', // **Border Added for Debugging**
//         boxSizing: 'border-box', // Ensures padding and border are included in the element's total width and height
//       }}
//     >
//       {/* Fixed Container for Search Bar */}
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: isSearchMoved ? 'flex-end' : 'center',
//           position: 'fixed', // Changed from 'absolute' to 'fixed'
//           top: isSearchMoved ? 'auto' : '50%',
//           bottom: isSearchMoved ? '35px' : 'auto',
//           left: '50%',
//           transform: isSearchMoved
//             ? 'translateX(-50%)'
//             : 'translate(-50%, -50%)',
//           transition: 'all 0.3s ease',
//           width: {
//             xs: '95%', // Mobile
//             sm: '70%', // Small screens
//             md: '50%', // Medium and up
//           },
//           maxWidth: '900px',
//           zIndex: 1000, // Higher z-index to sit on top
//           pointerEvents: 'none', // Allow clicks to pass through non-interactive areas
//           flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
//           // border: '2px solid orange', // **Border Added for Debugging**
//           boxSizing: 'border-box',
//         }}
//       >
//         <Box
//           sx={{
//             pointerEvents: 'auto', // Enable interactions within the SearchBar
//             width: '100%',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             // border: '2px dashed blue', // **Border Added for Debugging**
//             boxSizing: 'border-box',
//           }}
//         >
//           <SearchBar
//             searchQuery={searchQuery}
//             setSearchQuery={setSearchQuery}
//             handleSearchClick={handleSearchClick}
//             isProMode={isProMode}
//             handleToggle={handleToggle}
//             isSearchMoved={isSearchMoved}
//             sx={{
//               padding: { xs: '8px 12px', sm: '12px 16px' }, // Add padding for mobile
//               boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', // Subtle shadow for visibility
//               // border: '2px solid purple', // **Border Added for Debugging**
//               boxSizing: 'border-box',
//               width: '100%', // Ensure the SearchBar takes full width of its container
//               maxWidth: '800px', // Optional: limit the max width of the SearchBar
//             }}
//           />
//         </Box>
//       </Box>

//       {/* **New Fixed Box for {text} Positioned 10px Above SearchBar** */}
//       {!queries.length && !errorDisplay && !workspaceLoading && (
//         <Box
//           sx={{
//             position: 'fixed',
//             left: '50%',
//             bottom: isSearchMoved ? '45px' : '60%', // 35px (SearchBar bottom) + 10px or centered adjustment
//             transform: isSearchMoved
//               ? 'translateX(-50%)'
//               : 'translate(-50%, -50%)',
//             zIndex: 1001, // Higher than SearchBar to ensure visibility
//             pointerEvents: 'none', // Allow clicks to pass through
//             textAlign: 'center',
//           }}
//         >
//           <Typography
//             variant="h4"
//             color="#0066FF"
//             sx={{
//               boxSizing: 'border-box',
//             }}
//           >
//             {text}
//           </Typography>
//         </Box>
//       )}

//       {/* Content Area */}
//       <Container
//         maxWidth="xl"
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           flexGrow: 1, // Allow the Container to grow and fill available space
//           height: isSearchMoved ? 'calc(100% - 80px)' : '100%', // Conditional height
//           transition: 'all 0.3s ease',
//           position: 'relative',
//           zIndex: 0, // Base layer
//           mt: isSearchMoved ? 0 : 0, // Adjust top margin when search is moved
//           overflow: 'hidden', // Prevent overflow
//           // border: '2px solid blue', // **Border Added for Debugging**
//           boxSizing: 'border-box',
//         }}
//       >
//         {/* Loading State */}
//         {workspaceLoading ? (
//           <Box sx={{ width: '100%', mt: 4, boxSizing: 'border-box' }}> {/* **Border Added for Debugging** */}
//             <Skeleton
//               variant="text"
//               sx={{ width: '100%', maxWidth: 800, mx: 'auto' }}
//               height={60}
//             />
//             <Skeleton
//               variant="rectangular"
//               sx={{ width: '100%', maxWidth: 800, mx: 'auto' }}
//               height={400}
//             />
//             <Skeleton
//               variant="text"
//               sx={{ width: '100%', maxWidth: 800, mx: 'auto' }}
//               height={40}
//             />
//           </Box>
//         ) : (
//           <Box
//             sx={{
//               display: 'flex',
//               flexDirection: 'column',
//               flexGrow: 1,
//               alignItems: 'center',
//               justifyContent: queries.length ? 'flex-start' : 'center',
//               overflow: 'hidden', // Prevent overflow
//               // border: '2px solid brown', // **Border Added for Debugging**
//               boxSizing: 'border-box',
//             }}
//           >
//             {/* Initial State */}
//             {!queries.length && (
//               <Box
//                 sx={{
//                   textAlign: 'center',
//                   mb: 4,
//                   // border: '2px solid teal', // **Border Added for Debugging**
//                   boxSizing: 'border-box',
//                 }}
//               >
//                 {errorDisplay ? (
//                   <ErrorComponent errorDisplay={errorDisplay} />
//                 ) : (
//                   <Box
//                     sx={{
//                       // Removed transform to prevent overlapping with fixed {text}
//                       boxSizing: 'border-box',
//                       display: 'flex',
//                       alignItems: 'center',
//                       flexWrap: 'nowrap', // Prevent wrapping
//                       padding: '16px', // Optional: Add some padding
//                       // Optional: Handle overflow if necessary
//                       // overflow: 'hidden',
//                     }}
//                   >
//                     <Box
//                       sx={{
//                         display: 'flex',
//                         alignItems: 'center',
//                         // border: '2px solid lime', // **Border Added for Debugging**
//                         boxSizing: 'border-box',
//                         flexShrink: 0, // Prevent the image box from shrinking
//                         marginRight: '16px', // Space between image and text
//                       }}
//                     >
//                       {/* You can add an image or icon here if needed */}
//                     </Box>
//                   </Box>
//                 )}
//               </Box>
//             )}

//             {/* Results Area */}
//             {queries.length > 0 && (
//               <Box
//                 ref={scrollableDivRef}
//                 className="scrollable-container"
//                 sx={{
//                   width: '100%',
//                   flexGrow: 1,
//                   overflowY: 'auto', // Enable vertical scrolling
//                   scrollBehavior: 'smooth',
//                   // Removed mt: 2 and pb: 4 to reduce extra space
//                   maxHeight: '80vh', // Set your desired max height
//                   // border: '2px solid gray', // **Border Added for Debugging**
//                   boxSizing: 'border-box',
//                 }}
//               >
//                 <Paper
//                   elevation={0}
//                   sx={{
//                     width: '100%',
//                     maxWidth: mode === 'verify' ? '1100px' : '2200px',
//                     backgroundColor: 'transparent',
//                     // border: '2px solid purple', // **Border Added for Debugging**
//                     boxShadow: 'none',
//                     borderRadius: '10px',
//                     margin: 'auto',
//                     // padding: { xs: 1, sm: 2 },
//                     boxSizing: 'border-box',
//                   }}
//                 >
//                   {mode === 'verify' ? (
//                     <>
//                       {queries.map((query, index) => (
//                         <FactCheckDisplay
//                           key={index}
//                           query={query}
//                           id={ids[index] || null}
//                           setDone={() => {}}
//                           process
//                           maxWidth="100%"
//                           skipDisambiguation={skipDisambiguation}
//                           sx={{
//                             // border: "2px solid pink", // **Border Added for Debugging** (if FactCheckDisplay accepts sx prop)
//                             boxSizing: "border-box",
//                           }}
//                         />
//                       ))}
//                       {/* Add line breaks here */}
//                       <br />
//                       <br />
//                       <br />
//                       <br />
//                     </>
//                   ) : (
//                     <VideoParagraphComponent
//                       key={0}
//                       id={0}
//                       claim={link}
//                       email={email}
//                       readyin={false}
//                       sx={{
//                         // border: '2px solid cyan', // **Border Added for Debugging** (if VideoParagraphComponent accepts sx prop)
//                         boxSizing: 'border-box',
//                       }}
//                     />
//                   )}
//                   {/* **Sentinel Element for Scrolling** */}
//                   <div ref={bottomRef} 
//                     style={{ height: 0, 
//                       // border: '2px dotted black' 
//                       }} /> {/* **Border Added for Debugging** */}
//                 </Paper>
//               </Box>
//             )}
//           </Box>
//         )}
//       </Container>
//     </Box>
//   );
// };

// export default SearchComponent;



// SearchComponent.jsx
import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Box,
  Paper,
  Skeleton,
  useMediaQuery,
  useTheme,
  Container,
} from "@mui/material";
import FactCheckDisplay from '../FactCheckDisplay';
import './animation.css'; // Ensure .search-container styles are removed/commented out
import './scrollbar.css';
import { useAppContext } from '../AppProvider';
import { v4 as uuidv4 } from 'uuid';
import SearchBar from './searchBar';
import ErrorComponent from './ErrorComponent';
import VideoParagraphComponent from '../videoExpand';
import { useAuth0 } from '@auth0/auth0-react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from "axios";

import { useNavigate } from 'react-router-dom';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const SearchComponent = ({ isSearchMoved, setIsSearchMoved, isMdUp }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isProMode, setIsProMode] = useState(false);
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [searchVersion, setSearchVersion] = useState("basic"); // Default to 'basic'
  const [resultVersion, setResultVersion] = useState("basic"); // Default to 'basic'
  const [userVersion, setUserVersion] = useState("basic")
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [manualRefresh, setManualRefresh] = useState(false) // I am adding this for state update trigger
  const [searchCount, setSearchCount] = useState(0);
  const [isProDialogOpen, setProDialogOpen] = useState(false);
  const navigate = useNavigate(); // React Router's navigation hook

  const {
    version,
    setVersion,
    currentConversation,
    setConversations,
    setCurrentConversation,
    queries,
    setQueries,
    email,
    ids,
    workspaceLoading,
    mode,
    link,
    setLink,
    setIdHistory,
    setMode,
    errorDisplay,
    setErrorDisplay,
    setNewSearch,
    setOverlayLogin,
    setHideSearchBar,
    skipDisambiguation,
    isProUser
  } = useAppContext();
  
  const theme = useTheme();
  // const isSmUp = useMediaQuery(theme.breakpoints.up('sm')); // >=600px
  // const isMdUp = useMediaQuery(theme.breakpoints.up('md')); // >=900px
  const scrollableDivRef = useRef(null);
  const bottomRef = useRef(null); // **New Ref for Sentinel Element**

  const [text, setText] = useState("");
  const fullText = "Let's get fact-checking!"; // The full text to type out
  const typingSpeed = 50; // Typing speed in milliseconds
  const indexRef = useRef(0); // To keep track of the current index
  const timeoutRef = useRef(null); // To store the timeout ID

  useEffect(() => {
    const typeWriter = () => {
      // Ensure that fullText is defined and index is within bounds
      if (indexRef.current < fullText.length) {
        const currentChar = fullText[indexRef.current];
        
        // Prevent appending undefined or null characters
        if (currentChar !== undefined && currentChar !== null) {
          setText((prev) => prev + currentChar);
          indexRef.current += 1;
          timeoutRef.current = setTimeout(typeWriter, typingSpeed);
        } else {
          // If currentChar is undefined or null, stop the typing
          clearTimeout(timeoutRef.current);
        }
      }
    };

    typeWriter();

    // Cleanup function to clear the timeout when the component unmounts
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setText(''); // Optional: Reset text when unmounting
      indexRef.current = 0; // Reset index
    };
  }, [fullText, typingSpeed]);


  const handleToggle = (event) => {
    setIsProMode(event.target.checked);
    setVersion(event.target.checked ? "pro" : "basic");
  };

  useEffect(() => {
    setIsProMode(version === "pro");
  }, [version]);

  const sendCurrentConversation = async (email, currentConversation, query) => {
    try {
      await fetch('https://backend-word-testing-934923488639.us-central1.run.app/update_ids', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email, // user email
          id: {
            id: currentConversation, // current conversation ID
            query: query // conversation query
          }
        })
      });
    } catch (error) {
      console.error("Error sending current conversation:", error);
    }
  };

  const isUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleSearchClick = async () => {
    
    setErrorDisplay("");
    // Clear the search input and move the search UI if needed
    setSearchQuery("");
    setIsSearchMoved(true);
    const trimmedQuery = searchQuery.trim();

    if (!trimmedQuery) return;

    // console.log("searchCount is: ", searchCount)
    // console.log("isURL: ", isUrl(trimmedQuery))
    if(!isAuthenticated && searchCount >=1 || !isAuthenticated && isUrl(trimmedQuery) || !isAuthenticated && trimmedQuery.length>250){
      // alert('Please login to continue searching');
      setOverlayLogin(true)
      return;
    }

    let newMode = mode;
    let newQueries = [...queries];
    // console.log({ newQueries });
    let newLink = null;

    let newSearchState = false;
    // Determine if a new conversation should be started
    const shouldStartNewConversation =
      queries.length === 0 || isUrl(trimmedQuery) || mode === 'extractFacts';

    if (isUrl(trimmedQuery)) {
      
      newLink = trimmedQuery;
      newQueries = [trimmedQuery];
      newMode = 'extractFacts';
      setNewSearch(true);
    } else if (trimmedQuery.length > 250){
      newLink = trimmedQuery;
      newQueries = [trimmedQuery];
      newMode = 'extractFacts';
      setNewSearch(true);
    } else if (mode === 'extractFacts') {
      newMode = 'verify';
      newQueries = [trimmedQuery];
      setNewSearch(true);
      setManualRefresh(!manualRefresh)
    } else {
      newMode = 'verify';
      newQueries.push(trimmedQuery);
      setManualRefresh(!manualRefresh)
    }

    // Update states based on the above decisions
    if (newLink) setLink(newLink);
    setMode(newMode);
    setQueries(newQueries);
    // console.log({ trimmedQuery });

    if (shouldStartNewConversation) {
      setIdHistory({});
      let newConversationId = ""
      if (isAuthenticated){
        newConversationId = uuidv4();
      }else{
        newConversationId = "";

      }
      setCurrentConversation(newConversationId);

      setConversations((prevIds) => [
        ...prevIds,
        { query: trimmedQuery, id: newConversationId },
      ]);

      // Send conversation data and update URL without page navigation
      await sendCurrentConversation(email, newConversationId, trimmedQuery);
      window.history.replaceState(null, "", `/c/${newConversationId}`);
    }
    if(!isAuthenticated){
      setSearchCount(searchCount + 1)
    }
  };


  // useEffect(() => {
  //   console.log({ queries });
  // }, [queries]);

  // **Remove the previous useEffect for scrolling based on queries**

  // **Add a new useEffect to scroll to the sentinel element after each render**
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [queries, workspaceLoading, mode, link]); // Add dependencies as needed

  useEffect(() => {
    console.log("use effect checkPro running")
    const checkProSearches = async () => {
      try {
        if(isProUser == false){
          const response = await axios.post("https://stripe-facticity-payment-webhook-934923488639.us-central1.run.app/check_pro_searches", {
            userEmail: user.email,
          });
          //truth table logic
          // if sys is pro, user search pro -> pro
          // if sys is basic, user search basic -> basic
          // if sys is pro, user basic -> basic => alert/toast
          // if sys is basic, user pro -> basic
          
          if(response.data.success)
          {
            const userSearch = response.data.hasProSearches? 'pro': 'basic'
            console.log("userSearch is: ", userSearch)

            if(version == 'pro' && userSearch == 'pro')
            {
              setSearchVersion("pro")
              setResultVersion("pro")
            }
            else if (version == 'basic' && userSearch == 'basic') {
            setSearchVersion("basic")
            setResultVersion("basic")
            }
            else if (version == 'pro' && userSearch == 'basic') // throw toastie
            {
                setToastMessage("You have exhausted your 'pro' searches for the day. Your searches will be processed using 'basic' mode.");
                setToastOpen(true);
                setProDialogOpen(true)
                setSearchVersion("basic")
                setResultVersion("basic")
                setVersion("basic")
              }
            else {
              setSearchVersion("basic")
              setResultVersion("basic")
            }      

          }
          else{
            setSearchVersion("basic")
            setResultVersion("basic")
          }       

        }
        else { //user has a pro subscription
          setSearchVersion("pro")
          setResultVersion(version)
        }
      } catch (error) {
        console.error("Error checking pro searches:", error);
        setSearchVersion("basic"); // Fallback to 'basic' on error
      }
    };
    if(mode == 'verify')
    {
      checkProSearches();
    }
  }, [manualRefresh]); // Run on component mount

  const handleCloseToast = () => {
    setToastOpen(false);
  };

  const handleProDialogClose = () => {
    setProDialogOpen(false);
  };


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%', // Changed from '100vh' 
        
        width: '100%',
        position: 'relative',
        backgroundColor: 'transparent',
        overflow: 'hidden', // Prevent overflow
        // border: '2px solid red', // **Border Added for Debugging**
        boxSizing: 'border-box', // Ensures padding and border are included in the element's total width and height
      }}
    >
      {/* **Text Element Snapped to the Top** */}
      {!queries.length && !errorDisplay && !workspaceLoading && (
        <Box
          // sx={{
          //   width: '100%',
          //   padding: '16px 0', // Add vertical padding
          //   textAlign: 'center',
          //   backgroundColor: 'transparent', // Adjust if needed
          //   zIndex: 1, // Ensure it's above other elements if overlapping
          //   marginTop: isMdUp?'20vh':'15vh'
          // }}
          sx={{

            position: 'fixed',
            left: '50%',
            bottom: isSearchMoved ? '45px' : '55%', // 35px (SearchBar bottom) + 10px or centered adjustment
            transform: isSearchMoved
              ? 'translateX(-50%)'
              : 'translate(-50%, -50%)',
            zIndex: 1001, // Higher than SearchBar to ensure visibility
            pointerEvents: 'none', // Allow clicks to pass through
            textAlign: 'center',
            zIndex: -3000,
            width: '100%'
          }}
        >
          <Typography
            variant= {isMdUp?"h4":"h5"}
            color="#0066FF"
            sx={{
              boxSizing: 'border-box',
            }}
          >
            {text}
          </Typography>
        </Box>
      )}

      {/* Fixed Container for Search Bar */}
<Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: isSearchMoved ? 'flex-end' : 'center',
    position: 'fixed', // Changed from 'absolute' to 'fixed'
    top: isSearchMoved ? 'auto' : '50%',
    bottom: isSearchMoved ? '35px' : 'auto',
    left: '50%',
    transform: isSearchMoved
      ? 'translateX(-50%)'
      : 'translate(-50%, -40%)',
    transition: 'all 0.3s ease',
    width: {
      xs: '95%', // Mobile
      sm: '70%', // Small screens
      md: '50%', // Medium and up
    },
    maxWidth: '900px',
    zIndex: 1000, // Higher z-index to sit on top
    pointerEvents: 'none', // Allow clicks to pass through non-interactive areas
    flexDirection: 'column', // Stack vertically for both search bar and button
    gap: '16px', // Add spacing between SearchBar and button
    boxSizing: 'border-box',
    marginTop: {xs: '20px', sm: '20px'}

  }}
>
  <Box
    sx={{
      pointerEvents: 'auto', // Enable interactions within the SearchBar
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
    }}
  >
    <SearchBar
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      handleSearchClick={handleSearchClick}
      isProMode={isProMode}
      handleToggle={handleToggle}
      isSearchMoved={isSearchMoved}
      sx={{
        padding: { xs: '12px 12px', sm: '12px 16px' },
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: '800px',
        //margin: {xs: '12px', sm: '12px'}
      }}
    />
  </Box>
  {!isSearchMoved && (
  <Box
    sx={{
      pointerEvents: 'auto', // Enable interactions within the button
      width: '100%', // Ensure the button takes the same width as the SearchBar
      display: 'flex',
      justifyContent: 'center',
      boxSizing: 'border-box',
    }}
  >
    {/* <a
      href="https://tally.so/r/mV1V0v"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: 'none',
      }}
    >
      <button
        style={{
          padding: '12px 24px',
          fontSize: '15px',
          borderRadius: '4px',
          backgroundColor: '#0066FF', // Blue background
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          textAlign: 'center', // Center-align text within the button
        }}
      >
        Click here to join the waitlist of Facticity.AI's MS Word Add-in
        <br />
        and enjoy a one-month free trial!
      </button>
    </a> */}
  </Box>
)}
</Box>


      {/* Content Area */}
      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1, // Allow the Container to grow and fill available space
          height: isSearchMoved ? 'calc(100% - 80px)' : '100%', // Conditional height
          transition: 'all 0.3s ease',
          position: 'relative',
          zIndex: 0, // Base layer
          mt: isSearchMoved ? 0 : 0, // Adjust top margin when search is moved
          overflow: 'hidden', // Prevent overflow
          // border: '2px solid blue', // **Border Added for Debugging**
          boxSizing: 'border-box',
        }}
      >
        {/* Loading State */}
        {workspaceLoading ? (
          <Box sx={{ width: '100%', mt: 4, boxSizing: 'border-box' }}> {/* **Border Added for Debugging** */}
            <Skeleton
              variant="text"
              sx={{ width: '100%', maxWidth: 800, mx: 'auto' }}
              height={60}
            />
            <Skeleton
              variant="rectangular"
              sx={{ width: '100%', maxWidth: 800, mx: 'auto' }}
              height={400}
            />
            <Skeleton
              variant="text"
              sx={{ width: '100%', maxWidth: 800, mx: 'auto' }}
              height={40}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: queries.length ? 'flex-start' : 'center',
              overflow: 'hidden', // Prevent overflow
              // border: '2px solid brown', // **Border Added for Debugging**
              boxSizing: 'border-box',
            }}
          >
            {/* Initial State */}
            {!queries.length && (
              <Box
                sx={{
                  textAlign: 'center',
                  mb: 4,
                  // border: '2px solid teal', // **Border Added for Debugging**
                  boxSizing: 'border-box',
                }}
              >
                {errorDisplay ? (
                  <ErrorComponent errorDisplay={errorDisplay} />
                ) : (
                  <Box
                    sx={{
                      // Removed transform to prevent overlapping with fixed {text}
                      boxSizing: 'border-box',
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'nowrap', // Prevent wrapping
                      padding: '16px', // Optional: Add some padding
                      // Optional: Handle overflow if necessary
                      // overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        // border: '2px solid lime', // **Border Added for Debugging**
                        boxSizing: 'border-box',
                        flexShrink: 0, // Prevent the image box from shrinking
                        marginRight: '16px', // Space between image and text
                      }}
                    >
                      {/* You can add an image or icon here if needed */}
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {/* Results Area */}
            {queries.length > 0 && (
              <Box
                ref={scrollableDivRef}
                className="scrollable-container"
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  overflowY: 'auto', // Enable vertical scrolling
                  scrollBehavior: 'smooth',
                  // Removed mt: 2 and pb: 4 to reduce extra space
                  maxHeight: '80vh', // Set your desired max height
                  // border: '2px solid gray', // **Border Added for Debugging**
                  boxSizing: 'border-box',
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    width: '100%',
                    maxWidth: mode === 'verify' ? '1100px' : '2200px',
                    backgroundColor: 'transparent',
                    // border: '2px solid purple', // **Border Added for Debugging**
                    boxShadow: 'none',
                    borderRadius: '10px',
                    margin: 'auto',
                    // padding: { xs: 1, sm: 2 },
                    boxSizing: 'border-box',
                  }}
                >
                  {mode === 'verify' ? (
                    <>
                      {queries.map((query, index) => (
                        <FactCheckDisplay
                          key={index}
                          query={query}
                          id={ids[index] || null}
                          setDone={() => {}}
                          process
                          maxWidth="100%"
                          skipDisambiguation={skipDisambiguation}
                          sx={{
                            // border: "2px solid pink", // **Border Added for Debugging** (if FactCheckDisplay accepts sx prop)
                            boxSizing: "border-box",
                          
                          }}
                          version = {resultVersion}
                          isVideo = {false}
                          //isProUser = {isProUser}
                          
                        />
                      ))}
                      {/* Add line breaks here */}
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </>
                  ) : (
                    <VideoParagraphComponent
                      key={0}
                      id={0}
                      claim={link}
                      email={email}
                      readyin={false}
                      sx={{
                        // border: '2px solid cyan', // **Border Added for Debugging** (if VideoParagraphComponent accepts sx prop)
                        boxSizing: 'border-box',
                      }}
                      version = {searchVersion}
                    />
                  )}
                  {/* **Sentinel Element for Scrolling** */}
                  <div ref={bottomRef} 
                    style={{ height: 0, 
                      // border: '2px dotted black' 
                      }} /> {/* **Border Added for Debugging** */}
                </Paper>
              </Box>
            )}
          </Box>
        )}
      </Container>

      {/* Snackbar for toast */}
      <Snackbar
        open={toastOpen}
        autoHideDuration={3000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseToast} severity="warning" sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
      {/* Dialog Box to prompt pro subscription purchase */}

      <Dialog
      open={isProDialogOpen}
      onClose={handleProDialogClose}
      aria-labelledby="pro-signup-dialog-title"
      aria-describedby="pro-signup-dialog-description"
    >
      <DialogTitle id="pro-signup-dialog-title">Upgrade to Pro</DialogTitle>
      <DialogContent>
        <Typography id="pro-signup-dialog-description" sx={{ mt: 1 }}>
          You’ve reached the limit for 'pro' searches for the day. Upgrade to Pro to enjoy unlimited access to premium features, including advanced fact-checking and better results.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleProDialogClose} color="secondary">
          Not Now, Continue With Free
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleProDialogClose();
            //window.location.href = "/pricing"; // Navigate to pricing page
            navigate("/subscription")
          }}
        >
          Go Pro
        </Button>
      </DialogActions>
    </Dialog>

    </Box>
  );
};

export default SearchComponent;
